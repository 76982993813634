import React from 'react';
import './App.scss';
import { Grid, GridItem } from '@chakra-ui/react';

function App() {
  return (
    <Grid w="100%" h="100%" templateColumns='repeat(2, 1fr)'>
      <GridItem >Marian</GridItem>
      <GridItem>Seful la bani</GridItem>
    </Grid>
  );
}

export default App;
